import { ICON_TYPES } from "../shared";

const PRODUCTS_DETAILS = [
  {
    _id: 1,
    name: "DJI Air 3",
    controllers: [
      {
        _id: 1,
        title: "DJI RC 2 Controller",
        description: `Supporting DJI's new O4 long-range transmission system, the RC 2 Remote Controller from DJI is capable of receiving a 1080p resolution live feed from compatible drones. The footage is conveniently displayed on the RC 2's built-in 5.5" 1080p display.`,
      },
      {
        _id: 2,
        title: "DJI RC-N2 Controller",
        description: `Combining sleek design with outstanding performance, DJI RC 2 is a next-gen camera-drone remote controller that gives pilots advanced control and exceptional convenience. Its lightweight body houses a stunning 5.5-inch FHD display and a new processor. And the new O4 video transmission system provides a 20km max range* with an antenna system comprised of two transmitters and four receivers. Immerse yourself in any creative shot and capture spectacular moments with ease.`,
      },
    ],
    specfication: () => (
      <div class="pb-10">
        <ol class="list-disc pl-5 mb-5">
          <li>1/1.3" CMOS Sensors & up to 48MP Stills</li>
          <li>Up to 46 Minutes of Flight Time</li>
          <li>Omnidirectional Obstacle Sensing</li>
          <li>4K100, 4K60 HDR, or 2.7K Vertical Video</li>
          <li>O4 1080p60 12.4-Mile Transmission</li>
          <li>Multiple Pre-Programmed Flight Modes</li>
          <li>Dual Wide-Angle & 3x Telephoto Cameras</li>
        </ol>
        <div class="mb-5">
          <span class="font-bold">DJI Air 3 Drone</span> brings the next
          evolution of aerial and camera tech to the Air series with dual
          cameras, a 46-minute max flight time, omnidirectional obstacle
          sensing, O4 transmission, and many other intelligent features. With
          its advanced technology, compact and foldable design, and affordable
          price point, Air 3 is an unrivaled option for creators everywhere.
        </div>

        <div class="flex flex-col gap-4 mb-5">
          <h2 class="text-lg font-semibold">Fly More Kit includes:</h2>
          <ul class="list-disc pl-5">
            <li>3 Intelligent Flight Batteries (46-min max flight time)</li>
            <li>Air 3 Battery Charging Hub</li>
            <li>Shoulder Bag</li>
            <li>Spare Propellers</li>
          </ul>
        </div>

        <div class="w-full mb-5">
          <h2 class="text-2xl font-semibold mb-4">
            Complementary Camera Performance
          </h2>
          <p class="mb-6">
            The 1/1.3-inch-CMOS wide-angle camera and 3x medium tele camera
            deliver consistent image quality and more dynamic imaging
            possibilities.
          </p>

          <h2 class="text-2xl font-semibold mb-4">46-Min Max Flight Time</h2>
          <p class="mb-6">
            Air 3's 46-minute flight time is a 48% increase. The battery
            charging hub also allows power transfer among batteries.
          </p>

          <h2 class="text-2xl font-semibold mb-4">Dual-Camera 48MP Photos</h2>
          <p class="mb-6">
            Both cameras can deliver 48MP photos filled with true-to-life
            details that look great and allow seamless cropping in post for
            those who need it.
          </p>

          <h2 class="text-2xl font-semibold mb-4">4K/60fps HDR Videos</h2>
          <p class="mb-6">
            The two 1/1.3-inch CMOS sensors support dual native ISO for 4K/60fps
            HDR videos without cropping, providing rich, natural-looking
            results.
          </p>

          <h2 class="text-2xl font-semibold mb-4">
            Omnidirectional Obstacle Sensing
          </h2>
          <p class="mb-6">
            Air 3 can detect obstacles in all directions and can use APAS 5.0 to
            perform smoother avoidance for a safer overall flight experience.
          </p>

          <h2 class="text-2xl font-semibold mb-4">O4 HD Video Transmission</h2>
          <p>
            The next-gen O4 video transmission provides a max range of 20 km
            with increased stability, and a 1080p/60fps max-quality live feed.
          </p>
        </div>
      </div>
    ),
  },
  {
    _id: 2,
    name: "DJI Avata 2",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <span class="font-bold mb-3">Key Features</span>
        <ol class="list-disc pl-5 mb-5">
          <li>FPV Flight Experience</li>
          <li>Motion Control, Easy ACRO</li>
          <li>Tight Shots In Super-Wide 4K</li>
          <li>!/1.3-inch Image Sensor</li>
          <li>Built-in Propeller Guard</li>
          <li>Binocular Fisheye Visual Positioning</li>
          <li>Turtle Mode</li>
          <li>Cinematic Magic With One Tap</li>
        </ol>
        <div>
          <h2 class="text-2xl font-semibold mb-4">
            DJI Avata 2 Fly More Combo (Three Batteries).
          </h2>
          <p>
            The adrenaline-pumping DJI Avata 2 delivers an immersive FPV drone
            experience with improved imaging, safety, and battery life. Amp up
            the fun when you pair Avata 2 with the new DJI Goggles 3 and DJI RC
            Motion 3.
          </p>
          <h2 class="text-2xl font-semibold mb-4">DJI Goggles 3</h2>
          <h2 class="text-md font-medium">Visionary Breakthrough</h2>
          <p>
            DJI Goggles 3 now has Real View PiP (Picture-In-Picture). Observe
            your surroundings without removing the goggles, for more safety and
            immersion. The Micro-OLED high-definition display and
            ultra-low-latency transmission bring the world in to view.
          </p>
          <h2 class="text-2xl font-semibold">DJI RC Motion 3</h2>
          <h2 class="text-md font-medium">Feel Every Flight</h2>
          <p>
            Effortlessly perform difficult aerial acrobatics with DJI RC Motion
            3. New pilots can now perform spectacular flips, drifts, and other
            captivating maneuvers.
          </p>
          <br />
          <h2 class="text-md font-medium">Easy ACRO [2]</h2>
          <p>
            Soar, dive, and roll for stunning aerial footage with a single push.
            Capturing epic FPV footage with DJI RC Motion 3 and Avata 2 is
            easier than ever.
          </p>
          <br />
          <h2 class="text-md font-medium">One-Push Flip</h2>
          <p>
            360 front/back flip, adding fresh twists to your footage. One-Push
            Roll Roll 360 left/right, turning any shot from meh to magic.
          </p>
          <br />
          <h2 class="text-md font-medium">One-Push 180 Drift</h2>
          <p>
            High-speed lateral drifts to yaw spin 180 past objects and swiftly
            pull away, achieving dramatic visuals. <br />
          </p>
          <br />
          <h2 class="text-md font-medium">Advanced Maneuvers</h2>
          <p>
            Fly nimbly through tight spaces with the new DJI FPV Remote
            Controller 3 in Manual mode to showcase flying prowess and gutsy
            creativity.
          </p>
          <br />
          <h2 class="text-md font-medium">Tight Shots in Super-Wide 4K</h2>
          <p>
            Create breathtaking visuals with the 155 FOV of Avata 2 and its
            ability to swiftly approach and pull away from subjects. New pilots
            can quickly master aerobatic shots and capture fast-moving dynamic
            low-altitude shots that other drones can't.
          </p>
          <br />
          <h2 class="text-md font-medium">1/1.3-inch Image Sensor</h2>
          <p>
            The upgraded 1/1.3-inch image sensor expands the captured dynamic
            range, handling low-light conditions more effectively to capture
            outstanding footage on every flight.
          </p>
          <br />
          <h2 class="text-2xl font-semibold">Immersion Reimagined</h2>
          <h2 class="text-md font-medium">4K/60fps HDR Videos</h2>
          <p>
            Unleash your true aerial potential. The 4K/60fps HDR on Avata 2
            captures all the awesome of high-speed flight. In the goggles, you
            can adjust the sharpness and noise reduction levels for more finely
            tuned videos.
          </p>
          <br />
          <h2 class="text-md font-medium">RockSteady</h2>
          <p>
            Equipped with DJI's latest stabilization algorithm, Avata 2 captures
            smooth, steady footage even when flying at high speeds or in windy
            conditions.
          </p>
          <br />
          <h2 class="text-md font-medium">HorizonSteady</h2>
          <p>
            HorizonSteady handles horizontal rotations up to 360 , ensuring the
            captured footage remains locked on the horizon even if the drone
            turns sharply or sways dramatically.
          </p>
          <br />
          <h2 class="text-md font-medium">155 Ultra-Wide-Angle FOV</h2>
          <p>
            Amplify your vision and increase the immersion of low-altitude,
            high-speed flights. Avata 2 offers ultra-wide-angle, wide-angle, and
            normal-angle FOV options giving you the ability to adjust to the
            scenario.
          </p>
          <br />
          <h2 class="text-md font-medium">10-bit D-Log M</h2>
          <p>
            Capture rich detail in highlights and shadows and enhance content
            post-production opportunities while further streamlining
            color-grading.
          </p>
          <br />
          <h2 class="text-md font-medium">Comprehensive Safety</h2>
          <p>
            The updated integrated propeller guard design of Avata 2 makes it
            lighter and more agile, enabling it to navigate freely in tight
            spaces. [4] Releasing the accelerator on RC Motion 3 or pressing the
            lock button brings the drone to a halt, allowing you to handle
            unexpected situations calmly. In addition, the automatic Return to
            Home (RTH) function is initiated when the drone's battery is low or
            in case of signal loss, adding an extra layer of security.
          </p>
          <br />
          <h2 class="text-md font-medium">3D Preview | AR Preview</h2>
          <p>
            Advanced Positioning with New Sensors
            <br /> New binocular fisheye sensors enable downward and backward
            visual positioning during low-altitude and indoor flights, enhancing
            flight stability and safety.
          </p>
          <br />
          <h2 class="text-md font-medium">Turtle Mode</h2>
          <p>
            Avata 2 can automatically flip itself back into takeoff position
            when upside down with Turtle mode enabled, so you can quickly get
            airborne again.
          </p>
          <br />
          <h2 class="text-md font-medium">Flight Time/Video Transmission</h2>
          <p>
            The Sky's the Limit With Longer Flight
            <br />
            Avata 2 boasts a maximum flight time of 23 minutes [9] and supports
            PD fast charging, so you can quickly recharge it and take to the
            skies again.
            <br />
            The new DJI Avata 2 Two-Way Charging Hub [3] features a power
            accumulation function, allowing the transfer of remaining power from
            multiple batteries to the one with the highest charge level, [10]
            giving you an "extra battery" in critical moments.
          </p>
          <br />
          <h2 class="text-md font-medium">
            O4 Video Transmission, Enhanced Perception
          </h2>
          <p>
            DJI O4 video transmission's enhanced stability lets you fully
            immerse yourself in each flight and provides greater safety. Its
            two-transmitter, four-receiver, four-antenna design ensures strong
            anti-interference performance, and it boasts up to 13 km of maximum
            video transmission distance, [11] a transmission latency as low as
            24 ms, [12] 1080p/100fps high-definition image quality, and a
            maximum transmission bitrate of 60Mbps. [13]
          </p>
          <br />
          <h2 class="text-md font-medium">
            Boundless Passion, Wireless Sharing
          </h2>
          <p>
            Watch the DJI Goggles 3 live feed from Avata 2 on your smart
            device's DJI Fly App. [14] Connect up to 5 m away. [15] Sharing the
            excitement of FPV just got easier, and learning how to fly is more
            accessible than ever.
          </p>
          <br />
          <h2 class="text-md font-medium">Capture and Edit Seamlessly</h2>
          <p>
            Avata 2 comes equipped with 46GB internal storage, capable of
            storing approximately 90 minutes of 1080p/60fps video, ensuring you
            capture the best parts of each flight.
            <br />
            With Wi-Fi connectivity for fast file transfer to your phone,
            post-editing and sharing of your finished work is highly efficient.
          </p>
          <br />
          <h2 class="text-md font-medium">One-Tap VFX with LightCut</h2>
          <p>
            The LightCut app, connecting directly to DJI Avata 2 via Wi-Fi,
            allows you to intelligently add Sky VFX during post based on the
            flight trajectory captured in your videos, helping you easily create
            engaging aerial footage. Without the need to download the footage,
            the app offers editing and, combined with ?One-Tap Edit, allows you
            to produce polished content in a snap effortlessly.
          </p>
          <br />
          <h2 class="text-md font-medium">Sky VFX</h2>
          <p>
            After connecting the device and importing the footage, tap Sky VFX
            to add unique overlays, through-the-air text animations, virtual
            cockpits, and dashboard effects, enhancing the interactive fun and
            engagement of your immersive flight footage.
          </p>
          <br />
          <h2 class="text-md font-medium">One-Tap Edit</h2>
          <p>
            With AI's intelligent recognition of video types and themes, you can
            effortlessly filter through and select highlights with smooth camera
            work and exquisite composition. Coupled with a vast array of
            thematic templates, enabling you to produce polished content. The
            app also boasts intelligent sound effects, color recovery, one-click
            addition of device watermarks, and end credits, among other
            features.
          </p>
          <br />
          <h2 class="text-md font-medium">Wireless Connection</h2>
          <p>
            When Avata 2 is connected wirelessly to LightCut, you can preview
            the captured footage within the app and edit it without the need to
            download the footage, saving valuable space on your smartphone.
          </p>
          <br />
        </div>
      </div>
    ),
  },
  {
    _id: 3,
    name: "DJI Avata",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <span class="font-bold mb-3">Key Features</span>
        <ol class="list-disc pl-5 mb-5">
          <li>Immersive Flight Experience</li>
          <li>Intuitive Motion Control</li>
          <li>4K/60fps155° Super-Wide FOV Videos</li>
          <li>Powerful Video Stabilization</li>
          <li>Weighs About 410 g, Lightweight and Portable</li>
          <li>Enhanced Safety with Built-In Propeller Guard</li>
          <li>
            1080p/100fps video transmission quality: the lowest latency of 30 ms
          </li>
          <li>Dual 1080p Micro-OLED Screens</li>
        </ol>
        <div class="mb-5">
          Enjoy Immersive Flights with the{" "}
          <span class="font-bold">DJI Avata Explorer Combo FVP Drone</span>, and
          <span class="font-bold"> DJI Pre-View Combo</span> – A Cost-Effective
          Combination for Your Aerial Adventures.
        </div>

        <div class="w-full mb-5">
          <h2 class="text-2xl font-semibold mb-4">
            The Sky is Your Playground
          </h2>
          <p class="mb-6">
            When you combine Avata with the goggles and motion controller,
            flight becomes accessible to all. Experience the thrill of total
            immersion with unrivaled safety and control. Embrace your
            spontaneity and capture the world around you. Some of us were Born
            to Fly.
          </p>

          <h2 class="text-2xl font-semibold mb-4">
            Where Agility Meets Toughness
          </h2>
          <p class="mb-6">
            Compact and lightweight, DJI Avata is nimble in tight spaces. Every
            aspect of its design was made for you to be bold. The built-in
            propeller guard means that if Avata comes in contact with an object,
            it can bounce back, stay in the air, and can keep on flying.
          </p>
        </div>
      </div>
    ),
  },
  {
    _id: 4,
    name: "DJI Inspire 3",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <span class="font-bold mb-3">Key Features</span>
        <ol class="list-disc pl-5 mb-5">
          <li>X9-8K Air Full-Frame 3-Axis Gimbal</li>
          <li>8K25 CinemaDNG or 8K75 Apple ProRES</li>
          <li>CineCore 3.0 Image Processing</li>
          <li>Centimeter-Level RTK Positioning</li>
          <li>1/1.8" FVP Camera with 161° DFOV</li>
          <li>Spotlight Pro Subject Tracking</li>
          <li>Omnidirectional Obstacle Avoidance</li>
          <li>Up to 9.3-Mile Control via O3 Pro</li>
          <li>Up to 58.4 mph Flight Speed</li>
          <li>RC Plus Remote with 7" 1200-nit Display</li>
        </ol>
      </div>
    ),
  },
  {
    _id: 5,
    name: "DJI Mavic 3 Classic",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <span class="font-bold mb-3">Key Features</span>
        <ol class="list-disc pl-5 mb-5">
          <li>4/3 CMOS Hasselblad Camera</li>
          <li>5.1K/50fps Professional Imagery</li>
          <li>Hasselblad Natural Colour Solution</li>
          <li>46-Min Max Flight Time</li>
          <li>Omnidirectional Obstacle Sensing</li>
          <li>15km HD Video Transmission</li>
          <li>Advanced RTH</li>
          <li>Night Mode for Video Recordin</li>
        </ol>
        <div class="mb-5">
          Capture breathtaking aerial footage with the{" "}
          <span class="font-bold">DJI Mavic 3 Classic</span>! Equipped with a
          4/3 CMOS Hasselblad Camera, record stunning 5.1K HD videos. With a
          maximum flight time of 46 minutes and advanced obstacle sensing
          technology, you can confidently fly up to 15km and return home safely.
          Elevate your drone game today!
        </div>
        <div class="mb-5">
          Take your drone flying to the next level with the{" "}
          <span class="font-bold">DJI Mavic 3 Classic</span>! Featuring a 20MP
          wide-angle Hasselblad camera, 120 fps slow motion, and obstacle
          avoidance, this drone offers limitless possibilities for bold and
          daring adventurers. With 46 minutes of flight time, explore up to 9.3
          miles with ease. Includes the DJI standar remote controller for
          ultimate control.
        </div>
      </div>
    ),
  },
  {
    _id: 6,
    name: "DJI Mavic 3 Pro",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <span class="font-bold mb-4">Key Features</span>
        <ol class="list-disc pl-5 mb-5">
          <li> 3-Axis Gimbal with Tri-Camera System</li>
          <li>20MP 5.1K Wide-Angle 4/3 CMOS Hasselblad</li>
          <li>166mm 12MP 7x Zoom Telephoto</li>
          <li>70mm 48MP 3x Zoom Medium Telephoto Lens</li>
          <li>7 Intelligent Flight Modes</li>
          <li>Up to 43 Minutes of Flight Time</li>
          <li>O3+ Transmission with 9.3-Mile Range</li>
          <li>Omnidirectional Avoidance with APAS 5.0</li>
          <li>D-LOG, D-LOG M & HLG Post-Processing</li>
          <li>DJI RC PRO Remote Included</li>
          <li>
            Fly More Combo Kit includes: Three Intelligent Flight Batteries,
            100W Charging Hub, 100W USB-C Power Adapter, Data Cable, Shoulder
            Bag, Spare Propellers, Storage Cover.
          </li>
        </ol>
        <div class="mb-5">
          4/3 CMOS Hasselblad Camera | Dual Tele Cameras | Cine Only Tri-Camera
          Apple ProRes Support | 43-Min Max Flight Time | Omnidirectional
          Obstacle Sensing | 15km HD Video Transmission
        </div>
        <div class="mb-5">
          The DJI Mavic 3 Pro Drone and DJI RC PRO Controller, when paired with
          the Fly More Combo Kit, create a comprehensive package that enhances
          your drone adventures without compromising on features. This
          combination ensures you are fully equipped for all your aerial
          pursuits.
        </div>
        <div class="mb-5">
          Take your drone flying to the next level with the{" "}
          <span class="font-bold">DJI Mavic 3 Classic</span>! Featuring a 20MP
          wide-angle Hasselblad camera, 120 fps slow motion, and obstacle
          avoidance, this drone offers limitless possibilities for bold and
          daring adventurers. With 46 minutes of flight time, explore up to 9.3
          miles with ease. Includes the DJI standar remote controller for
          ultimate control.
        </div>
      </div>
    ),
  },
  {
    _id: 7,
    name: "DJI Mini 3 Pro",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <span class="font-bold mb-3">Key Features</span>
        <ol class="list-disc pl-5 mb-5">
          <li>Up to 4K60p Video & 48MP Raw Stills</li>
          <li>Tri-Directional Obstacle Avoidance</li>
          <li>Regulation-Friendly 8.8 oz Weight</li>
          <li>DJI RC Remote Included</li>
          <li>Up to 34 Minutes of Flight Time</li>
          <li>Rotating Gimbal for Vertical Shooting</li>
          <li>Slow Motion Video at Full HD 1080p120</li>
          <li>Up to 7.5 Mile Range with OcuSync 3</li>
          <li>FocusTrack Subject Tracking System</li>
          <li>Foldable Design for Easy Travel/Storage</li>
        </ol>
      </div>
    ),
  },
  {
    _id: 8,
    name: "DJI Mini 4 Pro",
    controllers: [
      {
        _id: 1,
        title: "DJI RC-N2 Controller",
        description: `This lightweight and easy-to-use remote controller comes with the built-in DJI Fly app, eliminating the need for smartphone use during flight. The high-definition screen delivers a crisp and clear view even under direct sunlight, optimizing your entire Mini 4 Pro experience.`,
      },
      {
        _id: 2,
        title: "Fly More Kit includes",
        description: `Two Intelligent Flight Battery Plus (47-min max flight time), Two-Way Charging Hub, Data Cable, Shoulder Bag, Spare Propellers, Screws.`,
      },
    ],
    specfication: () => (
      <div class="pb-10">
        <ol class="list-disc pl-5 mb-5">
          <li> Under 249 g</li>
          <li>4K/60fps HDR True Vertical Shooting</li>
          <li>Omnidirectional Obstacle Sensing</li>
          <li>Extended Battery Life</li>
          <li>20km FHD Video Transmission</li>
          <li>ActiveTrack 360°</li>
        </ol>
        <div class="mb-5">
          <span class="font-bold">DJI Mini 4 Pro Drone</span> is our most
          advanced mini-camera drone to date. [4] It integrates powerful imaging
          capabilities, omnidirectional obstacle sensing, ActiveTrack 360° with
          the new Trace Mode, and 20km FHD video transmission, bringing even
          more things to love for pros and beginners alike.
        </div>

        <div class="mb-5">
          <span class="font-semibold italic">
            Go Big With Mini DJI Mini 4 Pro
          </span>{" "}
          <br />
          Adding the RC 2 controller elevates your drone experience, thanks to
          its powerful processor ensuring smooth operation. With support for
          2.4GHz, 5.1GHz, and 5.8GHz frequencies, it enables HD low-latency
          video transmission up to 20km when paired with DJI Mini 4 Pro. The
          2T4R antenna system further enhances signal strength, and its
          lightweight design at just 420 grams guarantees a comfortable and
          enjoyable extended flight experience.
        </div>

        <div class="w-full mb-5">
          <h2 class="text-2xl font-semibold mb-4">Performance</h2>
          <p class="mb-6">
            Capture more complex details easily with Mini 4 Pro's camera,
            powered by a 1/1.3-inch CMOS sensor featuring Dual Native ISO
            Fusion, f/1.7 aperture, and 2.4μm 4-in-1 pixels. [5] More highlight
            and shadow details with a high dynamic range means uncompromising
            results in every frame.
          </p>

          <h2 class="text-2xl font-semibold mb-4">Sense More, Fly Safe</h2>
          <p class="mb-6">
            Omnidirectional obstacle sensing makes Mini 4 Pro mighty safe. With
            four wide-angle vision sensors and a pair of downward vision
            sensors, it detects obstacles from all angles. Advanced Pilot
            Assistance Systems (APAS) ensures additional safety by enabling
            automatic braking and bypassing during flight.
          </p>

          <h2 class="text-2xl font-semibold mb-4">A Touch of Cinematic</h2>
          <p class="mb-6">
            Mini 4 Pro has three effortless ways to get the shots you want:
            Spotlight, Point of Interest, and the revolutionary new ActiveTrack
            360° with enhanced subject tracking capabilities. Swipe a path on
            the trace wheel interface to capture seamless cinematic shots. With
            omnidirectional obstacle sensing, bypassing obstacles and achieving
            smoother, more stable tracking for pro-level results is shockingly
            simple.
          </p>

          <div class="flex flex-col gap-4 mb-5">
            <h2 class="text-lg font-semibold"> DJI RC 2 Controller</h2>
            <ul class="list-disc pl-5">
              <li> Enhanced CPU and GPU performance</li>
              <li>
                Supports 2.4GHz, 5.1GHz, and 5.8GHz for HD low-latency video
                transmission within 20km
              </li>
              <li>Boosted signal strength with 2T4R antenna system</li>
              <li>Lightweight design at 420 grams</li>
            </ul>
            <p class="mb-6">
              Adding the RC 2 controller elevates your drone experience, thanks
              to its powerful processor ensuring smooth operation. With support
              for 2.4GHz, 5.1GHz, and 5.8GHz frequencies, it enables HD
              low-latency video transmission up to 20km when paired with DJI
              Mini 4 Pro. The 2T4R antenna system further enhances signal
              strength, and its lightweight design at just 420 grams guarantees
              a comfortable and enjoyable extended flight experience
            </p>
          </div>
          {/* --------------------------------- */}
          <h2 class="text-2xl font-semibold mb-4">4K/60fps HDR Videos</h2>

          <h2 class="text-2xl font-semibold mb-4">
            Omnidirectional Obstacle Sensing
          </h2>
          <p class="mb-6">
            Air 3 can detect obstacles in all directions and can use APAS 5.0 to
            perform smoother avoidance for a safer overall flight experience.
          </p>

          <h2 class="text-2xl font-semibold mb-4">O4 HD Video Transmission</h2>
          <p>
            The next-gen O4 video transmission provides a max range of 20 km
            with increased stability, and a 1080p/60fps max-quality live feed.
          </p>
        </div>
      </div>
    ),
  },
  {
    _id: 9,
    name: "DJI Neo Fly More Combo",
    controllers: [
      {
        _id: 1,
        title: "DJI RC-N3 Controller",
        description: `The remote controller holder can secure the mobile device. The DJI Fly app on the mobile device displays high-definition camera feed in real-time.`,
      },
      {
        _id: 2,
        title: "Fly More Kit includes",
        description: `Two Intelligent Flight Battery Plus (18-min max flight time), Two-Way Charging Hub, Data Cable, Shoulder Bag, Spare Propellers, Screws.`,
      },
    ],
    specfication: () => (
      <div class="pb-10">
        <ol class="list-disc pl-5 mb-5">
          <li>35 g, Light & Portable</li>
          <li>Palm Takeoff & Landing</li>
          <li>AI Subject Tracking, QuickShots</li>
          <li>Multiple Control Options</li>
          <li>4K Ultra-Stabilized Video</li>
          <li>Full-Coverage Propeller Guards</li>
        </ol>
        <div class="mb-5">
          At 135 g, <span class="font-bold">DJI Neo</span> is DJI's lightest and most compact drone to date.
          Take off and land on your palm effortlessly without a remote controller, 
          and capture cinematic footage with YOU in focus. 
          Soar through breathtaking scenery, indoors and out, and be sure to include everybody in a group photo. 
          Enjoy a fresh perspective on everyday life with DJI Neo.
        </div>

        <div class="w-full mb-5">
          <h2 class="text-2xl font-semibold mb-4">From Your Hand to the Sky—Palm Takeoff</h2>
          <p class="mb-6">
          DJI Neo gracefully takes off and lands from your palm. Simply press the mode button on Neo, 
          select your desired shooting mode, and Neo automatically does the rest to capture impressive 
          footage, all without a remote controller!
          </p>

          <h2 class="text-2xl font-semibold mb-4">Take Center Stage With AI Subject Tracking</h2>
          <p class="mb-6">
          Whether cycling, skateboarding, or hiking, Neo keeps pace as your personal videographer 
          and ensures you are always in the spotlight. Equipped with AI algorithms, Neo can follow 
          the subject within frame, so you can set up captivating follow shots with ease.
          </p>

          <h2 class="text-2xl font-semibold mb-4">Spark Creativity With QuickShots</h2>
          <p class="mb-6">
          With just a press of your finger, let DJI Neo automatically film for you. 
          DJI Neo offers six intelligent shooting modes, providing dynamic angles to 
          elevate your creative footage.
          </p>

          <h2 class="text-2xl font-semibold mb-4">Flexible Control Methods</h2>
          <p class="mb-6">
          Compact yet capable, DJI Neo flies with style and captures hassle-free when 
          paired with the DJI Fly app.

          </p>

          <h2 class="text-2xl font-semibold mb-4">Multiple Control Methods</h2>
          <p class="mb-6">
          Compact yet capable, DJI Neo flies with style. It not only supports controller-free 
          aerial filming but can also be paired with the DJI Fly app, remote controllers, RC Motion, 
          DJI Goggles, and more for increased flight and camera control.
          </p>

          <h2 class="text-2xl font-semibold mb-4">Voice Control</h2>
          <p class="mb-6">
          "Hey Fly" - Wake the DJI Fly app with these words to enable voice control 
          and pilot DJI Neo with spoken flight directives.
          </p>
          
          
          <h2 class="text-2xl font-semibold mb-4">Uncompromised Image Quality</h2>
          <p class="mb-6">
          DJI Neo sports a 1/2-inch image sensor for snapping 12MP stills. Combined with DJI's 
          powerful stabilization algorithms, it can produce 4K UHD stabilized videos straight 
          out of the camera.
          </p>

          <h2 class="text-2xl font-semibold mb-4">Voice Control</h2>
          <p class="mb-6">
          "Hey Fly" - Wake the DJI Fly app with these words to enable voice control 
          and pilot DJI Neo with spoken flight directives.
          </p>

          <div class="flex flex-col gap-4 mb-5">
            <h2 class="text-lg font-semibold"> DJI RC-N3 Controller</h2>
            <ul class="list-disc pl-5">
              <li>Works with Smartphone & DJI Fly App</li>
              <li>2.4 & 5.8 GHz Dual-Band Transmission</li>
              <li>Displays HD Video Feed on Phone Display</li>
              <li>Up to 3.5 Hours of Operation</li>
            </ul>
            <p class="mb-6">
            Adding DJI RC-N3 Controller to the <span class="font-bold">DJI Neo</span>. The controller accepts any smartphone up to 7.1" long, 3.4" wide, 
            and 0.4" thick, which needs to be equipped with the DJI Fly app. The controller transmits piloting 
            information using 2.4 and 5.8 GHz dual-band frequencies and receives an HD video feed from the drone 
            to display on the phone's screen. The included cables support both Lightning and USB-C-compatible 
            phones. On a full charge, the RC-N3 can operate for up to 3.5 hours or, if its internal battery is 
            used to power the inserted phone, up to 1.5 hours.
            </p>
          </div>
          {/* --------------------------------- */}
         
        </div>
      </div>
    ),
  },
];

const ACCESSORIES_DETAILS = [
  {
    _id: 1,
    name: "DJI Power 1000",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <span class="font-bold mb-3">Key Features</span>
        <ol class="list-disc pl-5 mb-5">
          <li>2200-2400W High-Capacity Power Output</li>
          <li>Comprehensive Safety</li>
          <li>Fully Charged in 70 Mins</li>
          <li>Fast Charges DJI Drones</li>
          <li>Dual PD 3.1 USB-C Ports (140W Max)</li>
          <li>Supports Solar Charging</li>
          <li>Ultra-Quiet Operation</li>
          <li>Extensive Accessories</li>
        </ol>
      </div>
    ),
  },
  {
    _id: 2,
    name: "DJI RC Plus",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <div class="w-full mb-5">
          <h2 class="text-xl font-semibold mb-1">Overview</h2>
          <p>
            The DJI RC Plus Remote Controller with a 7-inch large high-bright
            screen is designed for industry users and supports Dual Operator
            mode.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Highlights</h2>
          <p>
            IP54 rated with protection against extreme environments. Dual
            external antennas provide stable image transmission with a maximum
            flight time of six hours. 8-core high-performance processor.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Tips</h2>
          <p>
            The terms HDMI, HDMI High-Definition Multimedia Interface, HDMI
            Trade dress and the HDMI Logos are trademarks or registered
            trademarks of HDMI Licensing Administrator, Inc.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">In The Box</h2>
          <p>DJI RC Plus Remote Controller x 1</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Specifications</h2>
          <ol class="list-disc pl-5">
            <li>Resolution: 1,920×1,200</li>
            <li>Maximum Brightness: 1,200 cd/m²</li>
            <li>Operating Temperature: -20 to 50 °C (-4° to 122° F)</li>
          </ol>

          <h2 class="text-xl font-semibold pt-4 mb-1">Compatibility</h2>
          <ol class="list-disc pl-5">
            <li>Matrice 350 RTK</li>
            <li>Matrice 300 RTK</li>
            <li>Matrice 30 Series</li>
          </ol>
        </div>
      </div>
    ),
  },
  {
    _id: 3,
    name: "DJI 65W Portable Charger",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <div class="w-full mb-5">
          <h2 class="text-xl font-semibold mb-1">Overview</h2>
          <p>
            The DJI 65W Portable Charger adopts GaN technology to accelerate the
            charging speed while also being more compact in size. It comes with
            a fixed USB-C cable and a USB-A output port. You can pair it up with
            your own USB-A cable to charge the battery and remote controller
            simultaneously. Supporting PPS and PD protocols, it can also charge
            your smartphone, laptop, and other devices.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Highlights</h2>
          <p>Universal ports, lightweight and portable</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">In The Box</h2>
          <p>DJI 65W Portable Charger × 1</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Specifications</h2>
          <ol class="list-disc pl-5">
            <li>
              <h2 class="text-md font-semibold">Input Voltage:</h2> 100-240 V, 2
              A, 50/60 Hz
            </li>
            <h2 class="text-md font-semibold pt-4 mb-1">Output USB-C:</h2>
            <li>5 V, 5 A</li>
            <li>9 V, 5 A</li>
            <li>12 V, 5 A</li>
            <li>15 V, 4.3 A</li>
            <li>20 V, 3.25 A</li>
            <li>5-20 V, 3.25 A</li>
            <li>USB-A: 5 V, 2 A</li>
            <li>Rated Power: 65 W</li>
            <li>Operating Temperature: 5° to 40° C (41° to 104° F)</li>
            <h2 class="text-md font-semibold pt-4 mb-1">Charging Time*:</h2>
            <li>
              DJI Mavic 3 Intelligent Flight Battery: approx. 1 hour and 36 mins
            </li>
            <li>DJI RC-N1: approx. 2 hours and 40 mins</li>
            <li>DJI RC: approx. 2 hours</li>
            <li>DJI RC Pro: approx. 2 hours</li>
            <li>DJI Avata Intelligent Flight Battery: approx. 47 mins</li>
            <li>DJI Goggles 2 Battery: approx. 1 hour and 50 mins</li>
          </ol>

          <h2 class="text-xl font-semibold pt-4 mb-1">Compatibility</h2>
          <ol class="list-disc pl-5">
            <li>Matrice 350 RTK</li>
            <li>Matrice 300 RTK</li>
            <li>Matrice 30 Series</li>
          </ol>
        </div>
      </div>
    ),
  },
  {
    _id: 4,
    name: "DJI Mavic 3 Series 100W Battery Charging Hub",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <div class="w-full mb-5">
          <h2 class="text-xl font-semibold mb-1">Overview</h2>
          <p>
            When connected to a designated charger, the DJI Mavic 3 100W Battery
            Charging Hub can expand the charging interface to three. The battery
            charging hub enhances efficiency by charging batteries in sequence
            according to their remaining power level, with more fully-charged
            batteries receiving power first.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Highlights</h2>
          <p>
            When connected to a 100W portable charger or car charger, DJI Mavic
            3 Battery Charging Hub (100W) can charge up to three batteries in
            sequence.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Tips</h2>
          <p>The charger is not included.</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">In The Box</h2>
          <p>DJI Mavic 3 100W Battery Charging Hub × 1</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Specifications</h2>
          <ol class="list-disc pl-5">
            <li>Operating Temperature: 5° to 40° C (41° to 104° F)</li>
            <li>Input: 5-20 V, max 5 A</li>
            <h2 class="text-md font-semibold pt-4 mb-1">Charging Time*:</h2>
            <li>
              With the DJI 100W USB-C Power Adapter: approx. 1 hour and 10
              minutes (for each battery)
            </li>
            <li>
              With the DJI 65W Portable Charger: approx. 1 hour and 36 minutes
              (for each battery)
            </li>
            <li>
              With the DJI 65W Car Charger: approx. 1 hour and 36 minutes (for
              each battery)
            </li>
          </ol>

          <h2 class="text-xl font-semibold pt-4 mb-1">Compatibility</h2>
          <ol class="list-disc pl-5">
            <li>DJI Mavic 3 Intelligent Flight Battery</li>
            <li>DJI 100W USB-C Power Adapter</li>
            <li>DJI 65W Portable Charger</li>
            <li>DJI 65W Car Charger</li>
          </ol>
        </div>
      </div>
    ),
  },
  {
    _id: 5,
    name: "DJI 65W Car Charger",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <div class="w-full mb-5">
          <h2 class="text-xl font-semibold mb-1">Overview</h2>
          <p>
            A must-have on the go, the 65W Car Charger can charge different
            devices in the car. It provides a stable output power of 65 W and
            two output ports (USB-C and USB-A) for charging the Intelligent
            Flight Battery and a remote controller at the same time. It also
            supports PPS and PD protocols to charge your smartphone, laptop, and
            other devices.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Highlights</h2>
          <p>Universal ports, easy to use</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Tips</h2>
          <p>The charger is not included.</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">In The Box</h2>
          <p>DJI 65W Car Charger × 1 & USB-C to USB-C Cable × 1</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Specifications</h2>
          <ol class="list-disc pl-5">
            <li>Operating Temperature: 5° to 40° C (41° to 104° F)</li>
            <li>Input: 5-20 V, max 5 A</li>
            <h2 class="text-md font-semibold pt-4 mb-1">Charging Time*:</h2>
            <li>
              With the DJI 100W USB-C Power Adapter: approx. 1 hour and 10
              minutes (for each battery)
            </li>
            <li>
              With the DJI 65W Portable Charger: approx. 1 hour and 36 minutes
              (for each battery)
            </li>
            <li>
              With the DJI 65W Car Charger: approx. 1 hour and 36 minutes (for
              each battery)
            </li>
          </ol>

          <h2 class="text-xl font-semibold pt-4 mb-1">Compatibility</h2>
          <ol class="list-disc pl-5">
            <li>DJI Mavic 3 Pro</li>
            <li>DJI Mavic 3 Pro Cine</li>
            <li>DJI Mavic 3 Classic</li>
            <li>DJI Mavic 3</li>
            <li>DJI Mavic 3 Cine</li>
            <li>DJI Air 3</li>
            <li>DJI Avata 2</li>
            <li>DJI Avata</li>
            <li>DJI Goggles 2 Battery</li>
          </ol>
        </div>
      </div>
    ),
  },
  {
    _id: 6,
    name: "DJI Air 3 Battery Charging Hub",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <div class="w-full mb-5">
          <h2 class="text-xl font-semibold mb-1">Overview</h2>
          <p>
            The battery charging hub supports a new power accumulation function,
            which can transfer the remaining power from multiple batteries to
            the battery with the highest remaining power. When connected to a
            designated charger, the battery charging hub can expand the charging
            interface to three, and enhances efficiency by charging batteries in
            sequence according to their remaining power level, with more
            fully-charged batteries receiving power first. The battery charging
            hub can also quickly charge remote controllers, smartphones, and
            other devices.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Tips</h2>
          <p>The charger is not included.</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">In The Box</h2>
          <p>Battery Charging Hub × 1</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Specifications</h2>
          <ol class="list-disc pl-5">
            <li>Operating Temperature: 5° to 40° C (41° to 104° F)</li>
            <li>Input: 5-20 V, max 5 A</li>
            <li>Output Power: 82 W (when used as a power bank)</li>
            <h2 class="text-md font-semibold pt-4 mb-1">Charging Time*:</h2>
            <li>
              With the DJI 100W USB-C Power Adapter: approx. 60 minutes (for
              each battery)
            </li>
            <li>
              With the DJI 65W Portable Charger: approx. 80 minutes (for each
              battery)
            </li>
            <li>
              With the DJI 65W Car Charger: approx. 80 minutes (for each
              battery)
            </li>
          </ol>

          <h2 class="text-xl font-semibold pt-4 mb-1">Compatibility</h2>
          <ol class="list-disc pl-5">
            <li>DJI Air 3 Intelligent Flight Battery</li>
            <li>DJI 100W USB-C Power Adapter</li>
            <li>DJI 65W Portable Charger</li>
            <li>DJI 65W Car Charger</li>
          </ol>
        </div>
      </div>
    ),
  },
  {
    _id: 7,
    name: "DJI Mini 4 Pro ND Filters Set (ND16/64/256)",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <div class="w-full mb-5">
          <h2 class="text-xl font-semibold mb-1">Overview</h2>
          <p>
            Adapt to strong lighting conditions with ND16/64/256 filters and
            always be ready for the perfect shot. Meets the needs of scenarios
            when shooting long exposure or in harsh lighting, e.g., long
            exposure timelapse.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">In The Box</h2>
          <p>
            ND16 Filter × 1<br />
            ND64 Filter × 1<br />
            ND256 Filter × 1
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Specifications</h2>
          <p>Weight: 0.75 g (each)</p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Compatibility</h2>
          <p>DJI Mini 4 Pro</p>
        </div>
      </div>
    ),
  },
  {
    _id: 8,
    name: "DJI Air 3 ND Filters Set (ND8/16/32/64)",
    controllers: [],
    specfication: () => (
      <div class="pb-10">
        <div class="w-full mb-5">
          <h2 class="text-xl font-semibold mb-1">Overview</h2>
          <p>
            The ND filters provide experienced users with precise control over
            shutter speed, allowing them to produce smooth footage in bright
            environments with lower shutter speeds. Flexible use of this set can
            ensure clean footage, even when the shutter opens to 180° with a low
            ISO*.
            <br />* Shutter Opening Angle: A common way of describing the
            shutter speed relative to the frame rate. Exposure Time = Shutter
            Opening Angle/(Frame Rate × 360°). For example, if the camera is
            recording at 24fps with the shutter open at 180°, the exposure time
            is 1/48 s.
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">In The Box</h2>
          <p>
            ND8 Filter × 1<br />
            ND16 Filter × 1<br />
            ND32 Filter × 1<br />
            ND64 Filter × 1
          </p>

          <h2 class="text-xl font-semibold pt-4 mb-1">Specifications</h2>
          <ol class="list-disc pl-5">
            <h2 class="text-md font-semibold pt-4 mb-1">
              Light Transmission Size:
            </h2>
            <li>13.4 mm (medium tele camera)</li>
            <li>12.4 mm (wide-angle camera)</li>

            <h2 class="text-md font-semibold pt-4 mb-1">Weight (single):</h2>
            <li>2.6 g</li>
          </ol>

          <h2 class="text-xl font-semibold pt-4 mb-1">Compatibility</h2>
          <p>DJI Air 3</p>
        </div>
      </div>
    ),
  },
];

const QUICK_CHAT = [
  {
    icon: ICON_TYPES.Shipping,
    text: "Free Shipping & Fast-Delivery Guarantee",
  },
  {
    icon: ICON_TYPES.CreditCard,
    text: "We accept credit cards and bank wires",
  },
  {
    icon: ICON_TYPES.Chat,
    text: `Order Service: <a href="https://api.whatsapp.com/send?phone=9964777744"
            target="_blank"
            class="text-blue-500 hover:text-blue-600 underline"
            rel="noopener noreferrer">Live Chat</a>`,
  },
];
export { ACCESSORIES_DETAILS, PRODUCTS_DETAILS, QUICK_CHAT };
